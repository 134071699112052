import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class FullTestimonials extends React.Component {
  renderArrows = () => {
    return (
      <div className="arrows">
        <div className="container">
          <div onClick={() => this.slider.slickNext()} className="next cursor-pointer">
            <span className="pe-7s-angle-right"></span>
          </div>
          <div onClick={() => this.slider.slickPrev()} className="prev cursor-pointer">
            <span className="pe-7s-angle-left"></span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <section
        className={`testimonials ${
          this.props.withIMG
            ? 'section-padding bg-img'
            : this.props.withCOLOR
            ? 'section-padding back-color'
            : this.props.noPadding
            ? ''
            : 'section-padding'
        } ${this.props.classText ? this.props.classText : ''}`}
        style={{
          backgroundImage: `${this.props.withIMG ? 'url(/img/testim-img.jpg)' : 'none'}`,
        }}
      >
        {this.props.showHead && (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10">
                <div className="sec-head  text-center">
                  <h6 className="wow fadeIn" data-wow-delay=".5s">
                    Testimonials
                  </h6>
                  <h3 className="wow color-font">
                    We strive to make our clients happy every step of the way
                  </h3>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container-fluid position-re  ">
          <div className="row wow fadeInUp justify-content-center " data-wow-delay=".5s">
            <div className="col-lg-7 ">
              <Slider
                className="slic-item"
                {...{
                  ref: (c) => (this.slider = c),
                  dots: true,
                  infinite: true,
                  arrows: false,
                  centerMode: true,
                  autoplay: true,
                  rows: 1,
                  slidesToScroll: 3,
                  slidesToShow: 1,
                  responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        centerMode: true,
                      },
                    },
                  ],
                }}
              >
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        <div className="img">
                          <img src="/img/clients/testimonial-3.jpg" alt="" />
                        </div>
                        <h6 className="author-name color-font">Claudia Glikman</h6>
                        <span className="author-details"> Realtor Estate Agent </span>
                      </div>
                    </div>
                  </div>
                  <p>
                    I am very happy with the work on my social media. The service is extremely
                    professional, the collaborators are helpful in everything I ask for, responsible
                    and very kind. Together we have made a great team. Thank you for all your work!
                  </p>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        <div className="img">
                          <img src="/img/clients/testimonial-1.jpg" alt="" />
                        </div>
                        <h6 className="author-name color-font">Astrid Zayas </h6>
                        <span className="author-details">Image Consultant</span>
                      </div>
                    </div>
                  </div>
                  <p>
                    Creativity, fun, punctuality, responsibility, and speed. Your work is above and
                    beyond. Thank you.
                  </p>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        <div className="img">
                          <img src="/img/clients/testimonial-2.jpeg" alt="" />
                        </div>
                        <h6 className="author-name color-font">Paula Andrea Apfelbaum </h6>
                        <span className="author-details">Owner at The multi-category fair</span>
                      </div>
                    </div>
                  </div>
                  <p>
                    Since we started with the service, we had an incredible change in our image,
                    much more adapted to the needs and search of our potential customers and today's
                    Internet users. This clearly reflected in greater loyalty from our 'customer
                    friends' and a huge explosion in sales on the web and in our branches.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
          {this.renderArrows()}
        </div>
      </section>
    );
  }
}

export default FullTestimonials;
