import React from 'react';
import { Link } from 'gatsby';
import appData from 'data/app.json';
import { ImWhatsapp } from 'react-icons/im';

const Footer = ({ hideBGCOLOR }) => {
  return (
    <footer className={`${!hideBGCOLOR ? 'sub-bg' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title">
                <h5>Contact Us</h5>
              </div>
              <ul>
                {/* <li>
                  <span className="icon pe-7s-map-marker"></span>
                  <div className="cont">
                    <h6>Officeal Address</h6>
                    <p>504 </p>
                  </div>
                </li> */}
                <li>
                  <a
                    href="https://wa.me/12708176840?text=Hi%20Simcha,%20I'd%20like%20to%20chat%20and%20learn%20more%20about%20your%20agency."
                    target="_blank"
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <ImWhatsapp size={28} color={'#25D366'} />
                    <div className="cont" style={{ marginLeft: 30 }}>
                      <h6>Chat With Us</h6>
                      <p>WhatsApp</p>
                    </div>
                  </a>
                </li>
                <li>
                  <div
                    className="cont"
                    style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row' }}
                    onClick={() => (window.location.href = 'mailto:info@simchasocialmedia.com')}
                  >
                    <span className="icon pe-7s-mail"></span>
                    <div>
                      <h6>Email Us</h6>
                      <p>info@simchasocialmedia.com</p>
                    </div>
                  </div>
                </li>
                <li>
                  <a href="tel:+1-270-817-6840" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <span className="icon pe-7s-call"></span>
                    </div>
                    <div>
                      <div className="cont">
                        <h6>Call Us</h6>
                        <p>(270) 817-6840</p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title">
                <h5>Recent News</h5>
              </div>
              <ul>
                <li>
                  <div className="img">
                    <Link to="/blog-details/blog-details-dark">
                      <img src="/img/blog-0001.jpeg" alt="" />
                    </Link>
                  </div>
                  <div className="sm-post">
                    <Link to="/blog-details/blog-details-dark">
                      <p>What is the power of Social Media?</p>
                    </Link>
                    <Link to="/blog-details/blog-details-dark">
                      <span className="date">23 September</span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <Link to="/blog-details/blog-details-second">
                      <img src="/img/blog-2.jpeg" alt="" />
                    </Link>
                  </div>
                  <div className="sm-post">
                    <Link to="/blog-details/blog-details-second">
                      <p>
                        The Importance of Community Management: How &amp; Why Your Brand Should Be
                        Engaging with Its Audience.
                      </p>
                    </Link>
                    <Link to="/blog-details/blog-details-second">
                      <span className="date">26 November</span>
                    </Link>
                  </div>
                </li>
                {/* <li>
                  <div className="subscribe">
                    <input type="text" placeholder="Type Your Email" />
                    <span className="subs pe-7s-paper-plane"></span>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="item">
              <div className="logo">
                <img src="/img/cuadrado-sin-fondo-blanco.png" alt="" />
              </div>
              <div className="social">
                <a href="https://www.instagram.com/simchasocialmediamanagement/" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com/profile.php?id=100089697546716" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/simcha-social-media-content-masters/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="https://twitter.com/simcha_is_here" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
              <div className="copy-right">
                <p>© 2022 | Simcha - Social Media Management Agency.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
