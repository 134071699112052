import React from 'react';
import Split from '../Split';

const PricingIntro = () => {
  return (
    <section className="intro-section section-padding pb-59">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="htit sm-mb30">
              <h4>Level Up Your Social Media Game with Our Plans</h4>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8">
            <div className="text">
              <Split>
                <p className="wow txt mb-10 words chars splitting" data-splitting>
                  Ready to take your social media game to the next level? Let us handle it for you!
                  <br /> Choose one of our expert social media management plans and elevate your
                  online presence today.
                </p>
              </Split>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingIntro;
