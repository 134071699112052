import React from 'react';
import cardMouseEffect from 'common/cardMouseEffect';
import { thumparallaxDown } from 'common/thumparallax';

const MinimalArea = () => {
  React.useEffect(() => {
    cardMouseEffect(document.querySelectorAll('.feat .items'));
    setTimeout(() => {
      thumparallaxDown();
    }, 1000);
  }, []);

  return (
    <section className="min-area sub-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="img">
              <img className="thumparallax-down" src="/img/about-us.jpeg" alt="" />
            </div>
          </div>
          <div className="col-lg-6 valign">
            <div className="content pt-0">
              <h4 className="wow color-font">About Us</h4>
              <p className="wow txt" data-splitting></p>
              <ul className="feat">
                <li className="wow fadeInUp" data-wow-delay=".2s">
                  <h6>
                    <span>1</span> Our Mission
                  </h6>
                  <p>
                    At Simcha, our mission is to empower businesses and organizations to succeed in
                    today's digital landscape through effective social media management. We strive
                    to provide you with the tools and strategies you need to elevate your brand's
                    online presence and engage with your target audience in meaningful ways.
                  </p>
                </li>
                <li className="wow fadeInUp" data-wow-delay=".4s">
                  <h6>
                    <span>2</span> Our Goals
                  </h6>
                  <p>
                    We're committed to providing you with the best social media management services
                    that enable you to achieve your business objectives. Our team of experienced
                    professionals works tirelessly to help you connect with your target audience,
                    grow your social media following, and boost engagement.
                  </p>
                </li>
                <li className="wow fadeInUp" data-wow-delay=".6s">
                  <h6>
                    <span>3</span> Why Us?
                  </h6>
                  <p>
                    We possess the necessary qualities to attain outstanding outcomes - a passion
                    for excellence, a drive for success, and a commitment to professionalism.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MinimalArea;
