import React from 'react';
import { Link } from 'gatsby';

const Intro2 = ({ sliderRef }) => {
  return (
    <header ref={sliderRef} className="slider-st valign position-re">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign">
            <div className="cont md-mb50">
              {/* <div className="sub-title mb-5">
                <h6>Your dream Social Media Agency</h6>
              </div> */}
              <h1 className="mb-10 fw-600">Boost Your Brand's Social Media Presence</h1>
              <p>
                Generate more leads, engage with your target audience, and boost sales. Take your
                brand to the next level by choosing your favorite plan now.
              </p>
              <Link to={`/pricing/pricing`} className="butn bord curve mt-30">
                <span>Get Started!</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img" style={{ width: '88%', height: '88%' }}>
              <img src="/img/home.jpeg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="line bottom left"></div>
    </header>
  );
};

export default Intro2;
