import React, { useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import emailjs from '@emailjs/browser';
import { ImWhatsapp } from 'react-icons/im';

import ContactFromDate from 'data/sections/form-info.json';

const ContactForm = () => {
  const messageRef = useRef();
  function validateEmail(value) {
    let error;
    if (!value) {
      error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }

  const sendEmail = () => {
    emailjs.sendForm(
      'service_nzmddxu',
      'template_mcvseh76',
      messageRef.current,
      '2XoFst3QTNPmR5k9O'
    );
    // .then(
    //   (result) => {
    //     alert('Your Message has been successfully sent!. We will be contacting you soon.');
    //   },
    //   (error) => {
    //     alert(
    //       'We are having some problems sending your message. We apologize, please try again.'
    //     );
    //   }
    // );
    alert('✅ Your message was sent successfully!. We will be contacting you soon.');
  };

  return (
    <section className="contact section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="form md-mb50">
              <h4 className="fw-700 color-font mb-50">Get In Touch</h4>
              <Formik
                ref={messageRef}
                initialValues={{
                  user_name: '',
                  user_email: '',
                  message: '',
                }}
              >
                {({ errors, touched }) => (
                  <Form ref={messageRef} onSubmit={sendEmail} id="contact-form">
                    <div className="controls">
                      <div className="form-group">
                        <Field
                          id="form_name"
                          type="text"
                          name="user_name"
                          placeholder="Name"
                          required="required"
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          validate={validateEmail}
                          id="form_email"
                          type="email"
                          name="user_email"
                          placeholder="Email"
                        />
                        {errors.email && touched.email && <div>{errors.email}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <Field
                        as="textarea"
                        id="form_message"
                        name="message"
                        placeholder="Message"
                        rows="4"
                        required="required"
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                      }}
                    >
                      <button
                        type="submit"
                        className="butn bord"
                        style={{ cursor: 'pointer', marginTop: '10px' }}
                      >
                        <span>Send Email</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="cont-info">
              <h4 className="fw-700 color-font mb-50">Contact Info</h4>
              <h3 className="wow" data-splitting>
                {ContactFromDate.title}
              </h3>
              <div className="item mb-40">
                <h5>
                  <a href="#0">{ContactFromDate.email}</a>
                </h5>
                <h5>{ContactFromDate.phone}</h5>
              </div>
              <h3 className="wow" data-splitting style={{ cursor: 'pointer' }}>
                Let's Chat
              </h3>
              <div className="item mb-40">
                <a
                  href="https://wa.me/12708176840?text=Hi%20Simcha,%20I'd%20like%20to%20chat%20and%20learn%20more%20about%20your%20agency."
                  target="_blank"
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                  }}
                >
                  <h6>WhatsApp</h6>
                  <div
                    style={{
                      marginTop: 10,
                      marginLeft: 10,
                    }}
                  >
                    <ImWhatsapp />
                  </div>
                </a>
              </div>
              <h3 className="wow" data-splitting>
                Our Office
              </h3>
              <div className="item">
                <h6>{ContactFromDate.location.first}</h6>
              </div>
              <div className="social mt-50">
                <a href="https://www.instagram.com/simchasocialmediamanagement/" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com/profile.php?id=100089697546716" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/simcha-social-media-content-masters/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="https://twitter.com/simcha_is_here" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
