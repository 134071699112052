import React from 'react';
import { Link } from 'gatsby';

const Blogs2 = () => {
  return (
    <section className="blog-list section-padding sub-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="head md-mb50">
              <h6 className="back-color">Get The Latest News</h6>
              <h3>What Our Trending News.</h3>
              <p>Social media can be a powerful tool for good.</p>
              <Link to="/blog-details/blog-details-dark">
                <span>More Blog Posts</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-7 offset-lg-1">
            <div className="item wow fadeInUp" data-wow-delay=".3s">
              <div className="img valign">
                <img src="/img/blog-0001.jpeg" alt="" />
              </div>
              <div className="cont valign">
                <div>
                  <div className="info">
                    <Link to="/blog-details/blog-details-dark" className="date">
                      <span>
                        <i>23</i> September
                      </span>
                    </Link>
                    <span>/</span>
                    <Link to="/blog-details/blog-details-dark" className="tag">
                      <span>Digital school of marketing</span>
                    </Link>
                  </div>
                  <h5>
                    <Link to="/blog-details/blog-details-dark">
                      What is the power of Social Media?
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="item wow fadeInUp" data-wow-delay=".5s">
              <div className="img valign">
                <img src="/img/blog-2.jpeg" alt="" />
              </div>
              <div className="cont valign">
                <div>
                  <div className="info">
                    <Link to="/blog-details/blog-details-second" className="date">
                      <span>
                        <i>26</i> November
                      </span>
                    </Link>
                    <span>/</span>
                    <Link to="/blog-details/blog-details-second" className="tag">
                      <span>Digital Agency Network</span>
                    </Link>
                  </div>
                  <h5>
                    <Link to="/blog-details/blog-details-second">
                      The Importance of Community Management: How &amp; Why Your Brand Should Be
                      Engaging with Its Audience
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="item wow fadeInUp" data-wow-delay=".3s">
              <div className="img valign">
                <img src="/img/blog-3.jpeg" alt="" />
              </div>
              <div className="cont valign">
                <div>
                  <div className="info">
                    <Link to="/blog-details/blog-details-third" className="date">
                      <span>
                        <i>07</i> Dicember
                      </span>
                    </Link>
                    <span>/</span>
                    <Link to="/blog-details/blog-details-third" className="tag">
                      <span>Oberlo</span>
                    </Link>
                  </div>
                  <h5>
                    <Link to="/blog-details/blog-details-third">
                      How many people use social media in 2023?
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blogs2;
