import React from 'react';
// import { Link } from 'gatsby';
// import { Formik, Form, Field } from 'formik';
// import { Link as ScrollLink } from 'react-scroll';

const BlogDetailsThird = ({ theme }) => {
  // const messageRef = React.useRef(null);

  // function validateEmail(value) {
  //   let error;
  //   if (!value) {
  //     error = 'Required';
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
  //     error = 'Invalid email address';
  //   }
  //   return error;
  // }
  // const sendMessage = (ms) => new Promise((r) => setTimeout(r, ms));

  return (
    <section className="blog-pg single section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="post">
              <div className="img">
                <img src="/img/blog-001.jpeg" alt="" />
              </div>
              <div className="content pt-60">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="cont">
                      <div className="spacial">
                        <p>
                          Social media’s infiltration into the lives of internet users has been on
                          the rise. The latest figures show that there are expected to be 4.89
                          billion social media users worldwide in 2023—a 6.5% increase from a year
                          ago. It is also 2.2 billion more than the number of social media users in
                          2017, which represents a whopping 79.1% jump in just five years. The
                          average annual growth rate over this period is 10.2%. Going forward, while
                          the number of social media users will continue to increase, the growth
                          rate is expected to plateau. The average annual growth rate from 2023 to
                          2027 is forecast at 5%. Despite the slowdown, it’s clear that social media
                          will continue to be a part of consumers’ daily lives, with smartphones and
                          mobile devices as the main driving force. As it stands, social media
                          statistics show that more than nine in ten (91%) social media users use
                          mobile devices to access social networks. Such statistics clearly show the
                          growing trend and reliance of consumers on social media, indicating a
                          clear opportunity for social media marketing opportunities for ecommerce
                          businesses worldwide.
                        </p>
                      </div>

                      <h4 className="extra-title">Social Media Usage by Region</h4>
                      <div className="spacial">
                        <p>
                          The accessibility of affordable smartphones, Wi-Fi coverage, and improving
                          networks play a massive role in increasing social media usage across the
                          globe. Currently, more than half (59.3%) of the global population uses
                          social media. Looking at how many people use social media by region, the
                          three areas with the highest number of social media users are located in
                          the world’s largest continent: Asia. Topping the list is East Asia, where
                          nearly 1.2 billion people use social media. The number of social media
                          users there makes up 14.9% of the global population. This should come as
                          no surprise, considering the region consists of China, the world’s most
                          populous country. South Asia is home to the second-highest number of
                          social media users with 847.17 million users. This is followed by
                          Southeast Asia with 506.3 million. Rounding out the top five are North
                          America, where there are 434.93 million social media users, and South
                          America, with 306.76 million users. Meanwhile, on the other end of the
                          spectrum, the regions with the fewest social media users are the Caribbean
                          with 24.71 million users and Central Africa with 21.13 million users.
                        </p>
                      </div>
                    </div>
                    <div className="author">
                      <div className="author-img">
                        <img src="/img/oberlo.png" alt="" />
                      </div>
                      <div className="info">
                        <h6>
                          <span>author :</span> Oberlo
                        </h6>

                        <div className="social">
                          <a
                            href="https://www.oberlo.com/statistics/how-many-people-use-social-media"
                            target="_blank"
                          >
                            <i className="fab fa fa-link"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="pagination">
                <span>
                  <a href="#0">Prev Post</a>
                </span>
                <span className="icon">
                  <Link to={`/blog/blog-dark`}>
                    <i className="fas fa-th-large"></i>
                  </Link>
                </span>
                <span className="text-right">
                  <a href="#0">Next Post</a>
                </span>
              </div>

              <div className="comments-area">
                <h5>Comments :</h5>
                <div className="item">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self esteem is that it needs
                      be nurturing. The main compont of a healthy environment.
                    </p>
                  </div>
                </div>
                <div className="item relped">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self esteem is that it needs
                      be nurturing. The main compont of a healthy environment.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self esteem is that it needs
                      be nurturing. The main compont of a healthy environment.
                    </p>
                  </div>
                </div>
              </div>

              <div className="comment-form" id="comment-form">
                <h5>Add Comment :</h5>
                <div className="form">
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      comment: '',
                    }}
                    onSubmit={async (values) => {
                      await sendMessage(500);
                      alert(JSON.stringify(values, null, 2));
                      // Reset the values
                      values.name = '';
                      values.email = '';
                      values.comment = '';
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <Field as="textarea" placeholder="Your Comment" name="comment" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Field type="text" placeholder="Your Name" name="name" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Field
                                type="email"
                                validate={validateEmail}
                                placeholder="Your Email"
                                name="email"
                              />
                              {errors.email && touched.email && <div>{errors.email}</div>}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group text-center">
                              <button
                                type="submit"
                                className={`nb butn ${
                                  theme ? (theme === 'light' ? 'dark' : '') : 'light'
                                } curve full-width`}
                              >
                                Comment
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik> 
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsThird;
