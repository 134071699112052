import React from 'react';
// import { Link } from 'gatsby';
// import { Formik, Form, Field } from 'formik';
// import { Link as ScrollLink } from 'react-scroll';

const BlogDetailsSecond = ({ theme }) => {
  // const messageRef = React.useRef(null);

  // function validateEmail(value) {
  //   let error;
  //   if (!value) {
  //     error = 'Required';
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
  //     error = 'Invalid email address';
  //   }
  //   return error;
  // }
  // const sendMessage = (ms) => new Promise((r) => setTimeout(r, ms));

  return (
    <section className="blog-pg single section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="post">
              <div className="img">
                <img src="/img/blog-1.jpeg" alt="" />
              </div>
              <div className="content pt-60">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="cont">
                      <div className="spacial">
                        <p>
                          With so many people communicating online, it can be hard to truly connect
                          with others and ensure that you’re clearly communicating your message. The
                          same goes for personal relationships as business relationships, more
                          specifically, those between a brand and their audience. What brands truly
                          need to succeed online is a sense of community, and with that, strong
                          community management.
                        </p>
                      </div>

                      <h4 className="extra-title">What Is Community Management?</h4>
                      <div className="spacial">
                        <p>
                          If you’re new to the social media game, you may not know what community
                          management is or where to begin. Community management is described as “how
                          your brand seizes opportunities to interact with your community in public
                          online spaces.” Think of when you’re personally scrolling through
                          Instagram and comment on a brand’s post, and notice that the brand liked
                          and responded to it. This helps to create an authentic connection behind a
                          brand, creating a relationship between the brand and its followers. These
                          types of interactions help spark interest with your audience, helping your
                          followers feel seen, heard, and appreciated.
                        </p>
                      </div>
                      <h4 className="extra-title">Obtain Feedback from Your Customers</h4>
                      <div className="spacial">
                        <p>
                          Through community management, your brand can gain insight into your
                          audience’s content preferences. This helps your brand to better understand
                          its audience and gauge interest in upcoming projects, products, and more.
                          For example, many brands utilize an Instagram poll to ask their followers
                          questions about what type of content they like to see, or take guesses as
                          to what an upcoming launch or new product may be. This gives the brand’s
                          audience an opportunity to feel involved in what the brand says and does,
                          while providing extremely useful feedback for the brand.
                        </p>
                      </div>
                      <h4 className="extra-title">
                        Provide Support for Audience Members, Followers, and Customers When Needed
                      </h4>
                      <div className="spacial">
                        <p>
                          A large portion of community management is customer support and success.
                          There are a few simple ways to think about customer support and success in
                          terms of community management – like forums, an FAQ section on your
                          brand’s Instagram page, and more. In this way, your customers can help
                          themselves (and each other) communicate with members of your team, locate
                          any resources they need, and review FAQs at any time. On social media,
                          customers often comment on a brand’s posts when they’re experiencing
                          issues, or will message their account directly with any questions. Brands
                          can then see exactly who is contacting them so that they can intervene and
                          provide the best assistance possible. This keeps things streamlined within
                          your brand’s community, and resolves issues in real-time.
                        </p>
                      </div>
                      <h4 className="extra-title">Start Building Your Community</h4>
                      <div className="spacial">
                        <p>
                          Community management is a new, yet powerful, industry. By implementing a
                          community management strategy at your company, you’ll be able to create a
                          safe place for your potential customers, followers, and more to
                          collaborate with your brand, provide you with feedback, and bond.
                        </p>
                      </div>
                    </div>
                    <div className="author">
                      <div className="author-img">
                        <img src="/img/blog-author.png" alt="" />
                      </div>
                      <div className="info">
                        <h6>
                          <span>author :</span> David Hernandez
                        </h6>
                        <p>
                          David Hernandez’s visionary leadership, dedicated marketing expertise and
                          drive to excel are at the heart of lotus823’s success and reputation as a
                          trusted partner in the integrated communications space
                        </p>
                        <div className="social">
                          <a
                            href="https://digitalagencynetwork.com/the-importance-of-community-management-how-why-your-brand-should-be-engaging-with-its-audience/"
                            target="_blank"
                          >
                            <i className="fab fa fa-link"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="pagination">
                <span>
                  <a href="#0">Prev Post</a>
                </span>
                <span className="icon">
                  <Link to={`/blog/blog-dark`}>
                    <i className="fas fa-th-large"></i>
                  </Link>
                </span>
                <span className="text-right">
                  <a href="#0">Next Post</a>
                </span>
              </div>

              <div className="comments-area">
                <h5>Comments :</h5>
                <div className="item">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self esteem is that it needs
                      be nurturing. The main compont of a healthy environment.
                    </p>
                  </div>
                </div>
                <div className="item relped">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self esteem is that it needs
                      be nurturing. The main compont of a healthy environment.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self esteem is that it needs
                      be nurturing. The main compont of a healthy environment.
                    </p>
                  </div>
                </div>
              </div>

              <div className="comment-form" id="comment-form">
                <h5>Add Comment :</h5>
                <div className="form">
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      comment: '',
                    }}
                    onSubmit={async (values) => {
                      await sendMessage(500);
                      alert(JSON.stringify(values, null, 2));
                      // Reset the values
                      values.name = '';
                      values.email = '';
                      values.comment = '';
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <Field as="textarea" placeholder="Your Comment" name="comment" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Field type="text" placeholder="Your Name" name="name" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Field
                                type="email"
                                validate={validateEmail}
                                placeholder="Your Email"
                                name="email"
                              />
                              {errors.email && touched.email && <div>{errors.email}</div>}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group text-center">
                              <button
                                type="submit"
                                className={`nb butn ${
                                  theme ? (theme === 'light' ? 'dark' : '') : 'light'
                                } curve full-width`}
                              >
                                Comment
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik> 
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsSecond;
