import React from 'react';

const PricingHeader = () => {
  return (
    <header className="pages-header circle-bg valign">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ">
            <div className="cont mt-100 mb-50 text-center">
              <h1 className="color-font fw-700">Plans &amp; Pricing</h1>
            </div>
          </div>

          <div className="col-lg-4" style={{ marginTop: 15 }}>
            <div className="img">
              <a
                href="https://wa.me/12708176840?text=Hi%20Simcha,%20I'd%20like%20to%20hear%20more%20about%20your%20Basic%20Plan."
                target="_blank"
              >
                <img src="/img/plans/plan-starter.png" alt="basic-plan" />
              </a>
            </div>
          </div>

          <div className="col-lg-4" style={{ marginTop: 15 }}>
            <div className="img">
              <a
                href="https://wa.me/12708176840?text=Hi%20Simcha,%20I'd%20like%20to%20hear%20more%20about%20your%20Growth%20Plan."
                target="_blank"
              >
                <img src="/img/plans/plan-growth.png" alt="growth-plan" />
              </a>
            </div>
          </div>

          <div className="col-lg-4" style={{ marginTop: 15 }}>
            <div className="img">
              <a
                href="https://wa.me/12708176840?text=Hi%20Simcha,%20I'd%20like%20to%20hear%20more%20about%20your%20Pro%20Plan."
                target="_blank"
              >
                <img src="/img/plans/plan-pro.png" alt="pro-plan" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="half sub-bg">
        <div className="circle-color">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
    </header>
  );
};

export default PricingHeader;
