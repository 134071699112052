import React from 'react';
// import { Link } from 'gatsby';
// import { Formik, Form, Field } from 'formik';
// import { Link as ScrollLink } from 'react-scroll';

const BlogDetails = ({ theme }) => {
  // const messageRef = React.useRef(null);

  // function validateEmail(value) {
  //   let error;
  //   if (!value) {
  //     error = 'Required';
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
  //     error = 'Invalid email address';
  //   }
  //   return error;
  // }
  // const sendMessage = (ms) => new Promise((r) => setTimeout(r, ms));

  return (
    <section className="blog-pg single section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="post">
              <div className="img">
                <img src="/img/blog-pic.jpeg" alt="" />
              </div>
              <div className="content pt-60">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="cont">
                      <h4 className="extra-title">Growing effect and influence on society.</h4>
                      <div className="spacial">
                        <p>
                          The effect of social media does not stop online. It is part of a much
                          bigger scope and although social media may firstly create a certain buzz
                          about a topic, overall the power that it generates will establish
                          word-of-mouth advocacy. The truth is that social media — when utilised
                          strategically over time — is the most powerful form of digital marketing
                          as well as market research that the world has ever seen. However, it’s not
                          a magic bean that grows overnight into organisational success. It’s a
                          platform for actual work. The art of social media marketing is knowing the
                          best places to put in that work so you are able to get results and not
                          just a lot of annoying people who think they’re your friends.
                        </p>
                      </div>

                      <h4 className="extra-title">Why choose Social Media?</h4>
                      <div className="spacial">
                        <p>
                          The reason why individuals choose social media as their primary source of
                          news is owing to its ability to deliver real-time updates, globally. For
                          example, with Facebook Live – and the ability to share on the spot – gives
                          massive audience coverage. Individuals with larger followings, who share
                          news and articles or give their opinions, have a big impact because
                          followers tend to trust the people who they are following on those
                          platforms. If you follow individuals on social media platforms who you
                          trust, it’s more probable that you will believe the news as well as
                          information which is shared by them. Social media platforms are also one
                          tool that companies and influencers can use to show authenticity as well
                          as transparency. For instance, the review system on Facebook pages assists
                          people with evaluating the credibility of a company be it a restaurant,
                          online retail store, media site or any sort of business. It’s probably
                          self-explanatory that social media (in other words, speaking with people)
                          is a marvellous tool for the first pillar of digital marketing — building
                          relationships with fans in addition to customers. You could be a big
                          company, a small company, or an individual. Any of these parties can use
                          social media platforms in order to show your customers that you aren’t a
                          fly-by-nighter. That you can be trusted. That you know your stuff.
                        </p>
                      </div>
                    </div>
                    <div className="author">
                      <div className="author-img">
                        <img src="/img/blog-company.png" alt="" />
                      </div>
                      <div className="info">
                        <h6>
                          <span>author :</span> Digital School of Marketing
                        </h6>
                        <p>
                          The Digital School of Marketing (DSM) is South Africa’s preferred provider
                          of accredited digital marketing education. .
                        </p>
                        <div className="social">
                          <a href="https://digitalschoolofmarketing.co.za/" target="_blank">
                            <i className="fab fa fa-link"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="pagination">
                <span>
                  <a href="#0">Prev Post</a>
                </span>
                <span className="icon">
                  <Link to={`/blog/blog-dark`}>
                    <i className="fas fa-th-large"></i>
                  </Link>
                </span>
                <span className="text-right">
                  <a href="#0">Next Post</a>
                </span>
              </div>

              <div className="comments-area">
                <h5>Comments :</h5>
                <div className="item">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self esteem is that it needs
                      be nurturing. The main compont of a healthy environment.
                    </p>
                  </div>
                </div>
                <div className="item relped">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self esteem is that it needs
                      be nurturing. The main compont of a healthy environment.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self esteem is that it needs
                      be nurturing. The main compont of a healthy environment.
                    </p>
                  </div>
                </div>
              </div>

              <div className="comment-form" id="comment-form">
                <h5>Add Comment :</h5>
                <div className="form">
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      comment: '',
                    }}
                    onSubmit={async (values) => {
                      await sendMessage(500);
                      alert(JSON.stringify(values, null, 2));
                      // Reset the values
                      values.name = '';
                      values.email = '';
                      values.comment = '';
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <Field as="textarea" placeholder="Your Comment" name="comment" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Field type="text" placeholder="Your Name" name="name" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Field
                                type="email"
                                validate={validateEmail}
                                placeholder="Your Email"
                                name="email"
                              />
                              {errors.email && touched.email && <div>{errors.email}</div>}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group text-center">
                              <button
                                type="submit"
                                className={`nb butn ${
                                  theme ? (theme === 'light' ? 'dark' : '') : 'light'
                                } curve full-width`}
                              >
                                Comment
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik> 
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetails;
