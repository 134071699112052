import React from 'react';
import { Link } from 'gatsby';
import initIsotope from '../../common/initIsotope';

const Portfolio = ({ grid, filterPosition }) => {
  React.useEffect(() => {
    setTimeout(() => {
      initIsotope();
    }, 1000);
  }, []);
  return (
    <section className="portfolio section-padding pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Portfolio
              </h6>
              <h3 className="wow color-font">Transform your brand with our powerful design</h3>
            </div>
          </div>
        </div>
      </div>
      <div className={`${grid === 3 ? 'container-fluid' : 'container'}`}>
        <div className="row">
          <div
            className={`filtering ${
              filterPosition === 'center'
                ? 'text-center'
                : filterPosition === 'left'
                ? 'text-left'
                : 'text-right'
            } col-12`}
          >
            {/* <div className="filter">
              <span data-filter="*" className="active">
                All
              </span>
              <span data-filter=".brand">Branding</span>
              <span data-filter=".web">Mobile App</span>
              <span data-filter=".graphic">Creative</span>
            </div> */}
          </div>

          <div className="gallery full-width">
            <div
              className={`${
                grid === 3 ? 'col-lg-4 col-md-6' : grid === 2 ? 'col-md-6 lg-mr' : 'col-12'
              } items graphic wow fadeInUp`}
              data-wow-delay=".4s"
            >
              <div className="item-img">
                {/* <Link to={`/project-details2/project-details2-dark`} className="imago wow"> */}
                <img src="/img/portfolio/portfolio-simcha/portfolio-1.jpg" alt="image" />
                <div className="item-img-overlay"></div>
                {/* </Link> */}
              </div>
              <div className="cont">
                <h6>Astrid Certified</h6>
                <span>
                  {/* <Link to="/works/works-dark"> */}
                  Image Consultant
                  {/* </Link> */}
                </span>
              </div>
            </div>

            <div
              className={`${
                grid === 3 ? 'col-lg-4 col-md-6' : grid === 2 ? 'col-md-6' : 'col-12'
              } items web wow fadeInUp`}
              data-wow-delay=".4s"
            >
              <div className="item-img">
                {/* <Link to={`/project-details2/project-details2-dark`} className="imago wow"> */}
                <img src="/img/portfolio/portfolio-simcha/portfolio-2.jpg" alt="image" />
                <div className="item-img-overlay"></div>
                {/* </Link> */}
              </div>
              <div className="cont">
                <h6>NyC Mobile Cba</h6>
                <span>
                  {/* <Link to="/works/works-dark"> */}
                  Electronics store
                  {/* <Link to="/works/works-dark"> */}
                </span>
              </div>
            </div>

            <div
              className={`${
                grid === 3 ? 'col-lg-4 col-md-6' : grid === 2 ? 'col-md-6' : 'col-12'
              } items brand wow fadeInUp`}
              data-wow-delay=".4s"
            >
              <div className="item-img">
                {/* <Link to={`/project-details2/project-details2-dark`} className="imago wow"> */}
                <img src="/img/portfolio/portfolio-simcha/portfolio-4.jpg" alt="image" />
                <div className="item-img-overlay"></div>
                {/* </Link> */}
              </div>
              <div className="cont">
                <h6>Claudia Glikman </h6>
                <span>
                  {/* <Link to="/works/works-dark"> */}
                  Real Estate Agent
                  {/* <Link to="/works/works-dark"> */}
                </span>
              </div>
            </div>

            <div
              className={`${
                grid === 3 ? 'col-lg-4 col-md-6' : grid === 2 ? 'col-md-6' : 'col-12'
              } items graphic wow fadeInUp`}
              data-wow-delay=".4s"
            >
              <div className="item-img">
                {/* <Link to={`/project-details2/project-details2-dark`} className="imago wow"> */}
                <img src="/img/portfolio/portfolio-simcha/portfolio-3.jpg" alt="image" />
                <div className="item-img-overlay"></div>
                {/* </Link> */}
              </div>
              <div className="cont">
                <h6>Italian Citizenship </h6>
                <span>
                  {/* <Link to="/works/works-dark"> */}
                  Legal study
                  {/* <Link to="/works/works-dark"> */}
                </span>
              </div>
            </div>

            <div
              className={`${
                grid === 3 ? 'col-lg-4 col-md-6' : grid === 2 ? 'col-md-6' : 'col-12'
              } items web wow fadeInUp`}
              data-wow-delay=".4s"
            >
              <div className="item-img">
                {/* <Link to={`/project-details2/project-details2-dark`} className="imago wow"> */}
                <img src="/img/portfolio/portfolio-simcha/portfolio-5.jpg" alt="image" />
                <div className="item-img-overlay"></div>
                {/* </Link> */}
              </div>
              <div className="cont">
                <h6>J&amp;B</h6>
                <span>
                  {/* <Link to="/works/works-dark"> */}
                  Dental Clinic
                  {/* <Link to="/works/works-dark"> */}
                </span>
              </div>
            </div>

            <div
              className={`${
                grid === 3 ? 'col-lg-4 col-md-6' : grid === 2 ? 'col-md-6' : 'col-12'
              } items brand wow fadeInUp`}
              data-wow-delay=".4s"
            >
              <div className="item-img">
                {/* <Link to={`/project-details2/project-details2-dark`} className="imago wow"> */}
                <img src="/img/portfolio/portfolio-simcha/portfolio-6.jpg" alt="image" />
                <div className="item-img-overlay"></div>
                {/* </Link> */}
              </div>
              <div className="cont">
                <h6>Golden Eagle</h6>
                <span>
                  {/* <Link to="/works/works-dark"> */}
                  Clothes store
                  {/* <Link to="/works/works-dark"> */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
