import React from 'react';

import Homepage2 from './homepage/home';

const Demos = () => <Homepage2 />;

export const Head = () => {
  return (
    <>
      <title>Simcha</title>
      <link rel="stylesheet" href="demo.css" />
    </>
  );
};

export default Demos;
