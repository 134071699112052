import React from 'react';
// import blog3Data from "data/blog3.json";
import DarkTheme from 'layouts/Dark';
import Navbar from 'components/Navbar/navbar';
import BlogDetailsThird from 'components/Blog-details/blog-details-third';
import PageHeader from 'components/Page-header/page-header';
import Footer from 'components/Footer/footer';

const BlogDetails3 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add('nav-scroll');
    } else {
      navbar.classList.remove('nav-scroll');
    }

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add('nav-scroll');
      } else {
        navbar.classList.remove('nav-scroll');
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="How many people use social media in 2023?"
        paragraph="Social media’s infiltration into the lives of internet users has been on the rise. The latest figures show that there are expected to be 4.89 billion social media users worldwide in 2023—a 6.5% increase from a year ago."
      />
      <BlogDetailsThird blog={'blog'} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Simcha - Blog </title>
    </>
  );
};

export default BlogDetails3;
