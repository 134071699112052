import React from 'react';
// import blog3Data from "data/blog3.json";
import DarkTheme from 'layouts/Dark';
import Navbar from 'components/Navbar/navbar';
import BlogDetailsSecond from 'components/Blog-details/blog-details-second';
import PageHeader from 'components/Page-header/page-header';
import Footer from 'components/Footer/footer';

const BlogDetails2 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add('nav-scroll');
    } else {
      navbar.classList.remove('nav-scroll');
    }

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add('nav-scroll');
      } else {
        navbar.classList.remove('nav-scroll');
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="The Importance of Community Management: How &amp; Why Your Brand Should Be
        Engaging with Its Audience"
        paragraph="In today’s highly digital and connected world, it may be strange to think that people can still feel disconnected from others."
      />
      <BlogDetailsSecond blog={'blog'} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Simcha - Blog </title>
    </>
  );
};

export default BlogDetails2;
